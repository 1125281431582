import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getTranslations, changeToLocalLinks, createMetaDesc } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';

import "../styles/page.scss"

class Page extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData, materialsData }, pageContext: { lang, prefix } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "matDocMain");
        const textDesc = createMetaDesc(page.frontmatter.summary || page.frontmatter.title);

        return (
            <Layout mainClassName="page" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} 
                translations={translations}>

                <SEO title={page.frontmatter.title} 
                    description={textDesc} lang={lang ? lang : "en" }/>
                
                <div className="page-container initial-padding">
                    <div className="page-top">
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.title }}></h1>
                    </div>

                    <div className="is-html with-headers max-width" 
                        ref={(t) => { this.isHtml = t }}
                        dangerouslySetInnerHTML={{ __html: page.html }}></div>

                    <div className="is-html with-headers teaching-materials-themes">
                        <h2>{i18Data[ lang ].labels.subjects}</h2>
                        <ul>
                        { materialsData.edges.map(({ node }, idx) => (
                        <li key={node.frontmatter.slug}>
                           <Link to={(prefix ? prefix + "/" : "/") + node.frontmatter.slug}>{idx + 1}. {node.frontmatter.simpleTitle}</Link>
                        </li>
                        ))
                        }
                        </ul>

                    </div>

                </div>

            </Layout>
        )
    }
}

export default Page


export const query = graphql`
    query MatDocMainQuery($lang: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "matDocMain" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        translateKey
                    }
                    html

                }
            }
        }
        materialsData: allMarkdownRemark(filter: { 
            frontmatter: { template:{ eq: "matDoc" }, 
            lang: { eq: $lang }
        }}) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        simpleTitle
                        translateKey
                        originalId
                    }
                }
            }
        }

    }
`